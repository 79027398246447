/* eslint-disable */
import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'آتیه ایرانیان | سکوی تأمین مالی جمعی آتیۀ ایرانیان'
    }
  },
  {
    path: '/Atiya',
    name: 'atiya',
    component: () => import( /* webpackChunkName: "atiya" */ '../views/Atiya.vue'),
    props: true,
    meta: {
      title: 'آتیه ایرانیان | درباره آتیا'
    }
  },
  {
    path: '/Project/:id',
    name: 'project',
    component: () => import( /* webpackChunkName: "project" */ '../views/Project.vue'),
    props: true,
    meta: {
      title: 'آتیه ایرانیان | جزئیات طرح'
    }
  },
  {
    path: '/Projects',
    name: 'projects',
    component: () => import( /* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      title: 'آتیه ایرانیان | طرح‌ها'
    }
  },
  {
    path: '/Contact',
    name: 'contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      title: 'آتیه ایرانیان | تماس باما'
    }
  },
  {
    path: '/Social',
    name: 'social',
    component: () => import( /* webpackChunkName: "social" */ '../views/Social.vue'),
    meta: {
      title: 'آتیه ایرانیان | شبکه‌های اجتماعی'
    }
  },
  {
    path: '/Newsletter',
    name: 'newsletter',
    component: () => import( /* webpackChunkName: "newsletter" */ '../views/Newsletter.vue'),
    meta: {
      title: 'آتیه ایرانیان | خبرنامه'
    }
  },
  {
    path: '/Partnership',
    name: 'partnership',
    component: () => import( /* webpackChunkName: "partnership" */ '../views/Partnership.vue'),
    meta: {
      title: 'آتیه ایرانیان | تقاضای همکاری'
    }
  },
  {
    path: '/Financing',
    name: 'financing',
    component: () => import( /* webpackChunkName: "financing" */ '../views/Financing.vue'),
    meta: {
      title: 'آتیه ایرانیان | درخواست تأمین مالی'
    }
  },
  {
    path: '/Funding',
    name: 'funding',
    component: () => import( /* webpackChunkName: "funding" */ '../views/Funding.vue'),
    meta: {
      title: 'آتیه ایرانیان | ثبت طرح و تأمین سرمایه'
    }
  },
  {
    path: '/Introduction',
    name: 'introduction',
    component: () => import( /* webpackChunkName: "introduction" */ '../views/Introduction.vue'),
    meta: {
      title: 'آتیه ایرانیان | آشنایی با سکوی آتیا ایرانیان'
    }
  },
  {
    path: '/Investment',
    name: 'investment',
    component: () => import( /* webpackChunkName: "investment" */ '../views/Investment.vue'),
    meta: {
      title: 'آتیه ایرانیان | ثبت نام و سرمایه گذاری'
    }
  },
  {
    path: '/News',
    name: 'news',
    component: () => import( /* webpackChunkName: "news" */ '../views/News.vue'),
    meta: {
      title: 'آتیه ایرانیان | خبرنامه'
    }
  },
  {
    path: '/Blog/:page',
    alias: ['/Blog/:page/:category'],
    name: 'blog',
    component: () => import( /* webpackChunkName: "blog" */ '../views/Blog.vue'),
    props: true,
    meta: {
      title: 'آتیه ایرانیان | بلاگ'
    }
  },
  {
    path: '/Post/:id',
    name: 'post',
    component: () => import( /* webpackChunkName: "post" */ '../views/Post.vue'),
    props: true,
    meta: {
      title: 'آتیه ایرانیان | پست بلاگ'
    }
  },
  {
    path: '/Knowledge',
    name: 'knowledge',
    component: () => import( /* webpackChunkName: "knowledge" */ '../views/Knowledge.vue'),
    meta: {
      title: 'آتیه ایرانیان | دانشنامه'
    }
  },
  {
    path: '/Authentication',
    alias: ['/Register'],
    name: 'authentication',
    component: () => import( /* webpackChunkName: "authentication" */ '../views/Authentication.vue'),
    meta: {
      title: 'آتیه ایرانیان | احراز هویت'
    }
  },
  {
    path: '/Dashboard',
    name: 'dashboard',
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    props: true,
    meta: {
      title: 'آتیه ایرانیان | پرتال سرمایه گذاری',
    },
    children: [{
        path: '/Dashboard',
        name: 'root',
        component: () => import( /* webpackChunkName: "root" */ '../views/Root.vue'),
      },
      {
        path: '/Dashboard/Form/:entity_type',
        alias: ['/Dashboard/Form/:entity_type/:id'],
        name: 'form',
        component: () => import( /* webpackChunkName: "form" */ '../components/Form.vue'),
        props: true
      },
      {
        path: '/Dashboard/List/:entity_type/:page/:size',
        name: 'list',
        component: () => import( /* webpackChunkName: "list" */ '../components/List.vue'),
        props: true
      },
      {
        path: '/Dashboard/Receipt/:id',
        name: 'receipt',
        component: () => import( /* webpackChunkName: "receipt" */ '../components/Receipt.vue'),
        props: true
      },
      {
        path: '/Dashboard/SIP',
        name: 'sip',
        component: () => import( /* webpackChunkName: "sip" */ '../components/Phone/SIP.vue')
      },
      {
        path: '/Dashboard/Ticket',
        name: 'ticket',
        component: () => import( /* webpackChunkName: "ticket" */ '../views/Ticket.vue'),
      },
      {
        path: '/Dashboard/Investment/Deposit',
        name: 'investments',
        component: () => import( /* webpackChunkName: "investments" */ '../views/Investments.vue'),
        props: true
      },
      {
        path: '/Dashboard/Offer/:status',
        name: 'offers',
        component: () => import( /* webpackChunkName: "offers" */ '../views/Offers.vue'),
        props: true
      },
      {
        path: '/Dashboard/Calculator',
        name: 'calculator',
        component: () => import( /* webpackChunkName: "calculator" */ '../views/Calculator.vue')
      },
      {
        path: '/Dashboard/Inquiry/Internal',
        name: 'internalinquiry',
        component: () => import( /* webpackChunkName: "internalinquiry" */ '../views/InternalInquiry.vue')
      },
      {
        path: '/Dashboard/Inquiry/External',
        name: 'externalinquiry',
        component: () => import( /* webpackChunkName: "externalinquiry" */ '../views/ExternalInquiry.vue')
      },
      {
        path: '/Dashboard/Inquiry/External/Warranty',
        name: 'warrantyinquiry',
        component: () => import( /* webpackChunkName: "warrantyinquiry" */ '../views/WarrantyInquiry.vue')
      },
      {
        path: '/Dashboard/Inquiry/External/Sejam',
        name: 'sejaminquiry',
        component: () => import( /* webpackChunkName: "sejaminquiry" */ '../views/SejamInquiry.vue')
      },
      {
        path: '/Dashboard/Marketing',
        name: 'marketing',
        component: () => import( /* webpackChunkName: "marketing" */ '../views/Marketing.vue')
      },
      {
        path: '/Dashboard/2FA',
        name: '2fa',
        component: () => import( /* webpackChunkName: "2fa" */ '../views/2FA.vue')
      },
      {
        path: '/Dashboard/Payment',
        name: 'paymentorder',
        component: () => import( /* webpackChunkName: "paymentorder" */ '../views/PaymentOrder.vue')
      },
      {
        path: '/Dashboard/Report',
        name: 'report',
        component: () => import( /* webpackChunkName: "report" */ '../views/Report.vue')
      },
      {
        path: '/Dashboard/Regulator',
        name: 'regulator',
        component: () => import( /* webpackChunkName: "regulator" */ '../views/Regulator.vue')
      },
      {
        path: '/Dashboard/:pathMatch(.*)*',
        name: 'maintenance',
        component: () => import( /* webpackChunkName: "maintenance" */ '../components/Maintenance.vue')
      }
    ]
  },
  {
    path: '/ComingSoon',
    name: 'comingsoon',
    component: () => import( /* webpackChunkName: "comingsoon" */ '../views/Maintenance.vue'),
    meta: {
      title: 'آتیه ایرانیان | درحال بروزرسانی'
    }
  },
  {
    path: '/SSO',
    name: 'sso',
    component: () => import( /* webpackChunkName: "sso" */ '../views/SSO.vue'),
    meta: {
      title: 'آتیه ایرانیان | ورود سریع '
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../components/NotFound.vue'),
    meta: {
      title: 'آتیه ایرانیان | صفحه یافت نشد'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.querySelector(to.hash)
      if (element) {
        return {
          el: to.hash,
          behavior: 'smooth'
        }
      }
    }
    return {
      top: 0
    }
  }
})

const urlParams = new URLSearchParams(window.location.search)
const utm_source = urlParams.get('utm_source') || localStorage.getItem('utm_source')
const utm_medium = urlParams.get('utm_medium') || localStorage.getItem('utm_medium')
const utm_campaign = urlParams.get('utm_campaign') || localStorage.getItem('utm_campaign')

if (utm_source)
  localStorage.setItem('utm_source', utm_source)
if (utm_medium)
  localStorage.setItem('utm_medium', utm_medium)
if (utm_campaign)
  localStorage.setItem('utm_campaign', utm_campaign)

router.beforeEach((to, from, next) => {
  if (to.path.includes('Dashboard'))
    return next()

  const utm_source = localStorage.getItem('utm_source')
  const utm_medium = localStorage.getItem('utm_medium')
  const utm_campaign = localStorage.getItem('utm_campaign')

  if (utm_source || utm_medium) {
    const newQuery = {
      ...to.query
    }
    if (utm_source)
      newQuery.utm_source = utm_source
    if (utm_medium)
      newQuery.utm_medium = utm_medium
    if (utm_campaign)
      newQuery.utm_campaign = utm_campaign

    if (JSON.stringify(newQuery) !== JSON.stringify(to.query)) {
      return next({
        path: to.path,
        query: newQuery,
        replace: true
      })
    }
  }

  next()
})

export default router
