/* eslint-disable */
import {
  createApp
} from 'vue'
import App from './App.vue'
import base from './base/base'
import router from './router'
import {
  initializeApp
} from 'firebase/app'

import VueCookies from 'vue-cookies'
import Notifications from '@kyvg/vue3-notification'
import Vue3ConfirmDialog from 'vue3-confirm-dialog'
import StepProgress from 'vue-step-progress'
import Select2 from 'vue3-select2-component'
import {
  QuillEditor
} from '@vueup/vue-quill'
import VueLazyload from 'vue3-lazyload'
import './registerServiceWorker'

const firebaseConfig = {
  apiKey: "AIzaSyDSkWbEUUWXRyW-j21EZF0-bGTpYsikNM8",
  authDomain: "atiya-crowd.firebaseapp.com",
  projectId: "atiya-crowd",
  storageBucket: "atiya-crowd.appspot.com",
  messagingSenderId: "611275793858",
  appId: "1:611275793858:web:b1f223cc27ec0dfae7a25b",
  measurementId: "G-QJH4BVP3KJ"
}

initializeApp(firebaseConfig)

const app = createApp(App)

app.provide('base', base)

app.config.devtools = false
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}

  app.config.errorHandler = (error, instance, info) => {}

  app.config.warnHandler = (message, instance, trace) => {}
}

router.beforeEach((to) => {
  const {
    title,
    description
  } = to.meta
  document.title = title || 'آتیه ایرانیان'
  const descriptionElement = document.querySelector('head meta[name="description"]')
  if (descriptionElement)
    descriptionElement.setAttribute('content', description || '')

  if (['www.atiyacrowd.ir', 'atiyacrowd.ir', 'www.atiyehiraniancf.com', 'www.atiyehiraniancf.ir', 'atiyehiraniancf.ir'].includes(window.location.hostname))
    window.location.href = 'https://atiyehiraniancf.com' + to.fullPath
})

app
  .use(router)
  .use(VueCookies)
  .use(Notifications)
  .use(Vue3ConfirmDialog)
  .use(VueLazyload, {
    loading: '',
    error: '',
    attempt: 1
  })

app.component('Confirm', Vue3ConfirmDialog.default)
app.component('StepProgress', StepProgress)
app.component('Select2', Select2)
app.component('QuillEditor', QuillEditor)

app.mount('#app')
